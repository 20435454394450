<template>
  <div class="mb-7">
    <slot
      :current-step
      name="sticky-bar"
      :title="selectedTitle"
      :total-steps="funnel.length"
    />

    <StepIncompatibleKeyboard
      :breadcrumb
      class="pb-[144px] pt-0 md:pb-[44px] md:pt-24"
      :price
      :product
      :technical-specifications
    />

    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <aside
      v-if="funnel[0] && funnel[0].id === 'grades'"
      :key="funnel[0].id"
      ref="firstStep"
      class="pb-32 md:pb-36 md:pt-24"
      @focus="() => handleFocusedStep(0)"
      @mouseenter="() => handleFocusedStep(0)"
      @mouseover="() => handleFocusedStep(0)"
      @touchstart="() => handleFocusedStep(0)"
    >
      <Step :is-focused="isFocusedStep(0)" :product :step="funnel[0]">
        <template #title-grades>
          <RevLink
            v-if="funnel[0].guidance?.clickable"
            class="body-2-link block pl-12 md:hidden"
            variant="link"
            @click="() => openModal(MODAL_NAMES.CONDITION_COMPARISON)"
          >
            {{ i18n(translations.conditionModalLinkSmallScreen) }}
          </RevLink>
        </template>

        <template #description-grades>
          <p class="body-2 -mt-16 mb-16 block md:hidden">
            {{ i18n(translations.conditionDescription) }}
          </p>
        </template>

        <template #guidance-grades>
          <ConditionGuidance
            :brand="product.brand"
            :content="funnel[0].sideContent?.content"
            :tracking-model="product.model"
          />
        </template>
      </Step>
    </aside>

    <aside
      v-if="funnelWithStickyScrollSteps.length > 0"
      class="flex justify-center"
    >
      <div
        class="relative mr-32 hidden w-1/2 max-w-[498px] md:flex md:min-w-[337px] md:flex-col md:items-center lg:mr-64"
        style="container-type: size"
      >
        <div :class="['sticky w-full', stickyInTheCenter]">
          <Gallery
            :allow-media-viewer="false"
            :allow-partners-images="false"
            class="mb-16"
            :images
            :product
            :tracking-product-model="product.model"
            tracking-zone="pp_step_color_carousel"
          />
        </div>
      </div>
      <div>
        <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
        <div
          v-for="(step, index) in funnelWithStickyScrollSteps"
          :key="step.id"
          ref="steps"
          class="py-32 md:py-64"
          @focus="() => handleFocusedStep(index + 1)"
          @mouseenter="() => handleFocusedStep(index + 1)"
          @mouseover="() => handleFocusedStep(index + 1)"
          @touchstart="() => handleFocusedStep(index + 1)"
        >
          <StepTradeIn
            v-if="step.id === 'trade_in'"
            :category="product.tracking.categoryName"
            :is-focused="
              isFocusedStep(index + 1 + funnelWithStickyScrollSteps.length)
            "
            :model="product.model"
            :price
            without-left-template
          />
          <Step
            v-else
            :is-focused="isFocusedStep(index + 1)"
            :product
            :step
            without-left-template
          >
            <template #guidance-color>
              <Gallery
                :allow-media-viewer="false"
                :allow-partners-images="false"
                class="mb-16"
                :images
                :product
                :tracking-product-model="product.model"
                tracking-zone="pp_step_color_carousel"
              />
            </template>

            <template #step-end-mobile_plan>
              <StepEndMobilePlan :step :tracking-model="product.model" />
            </template>

            <template #step-end-dual_sim>
              <VisibleByVerizonSIMAdvertisement
                v-if="
                  hasPartnerPromoCode(
                    product.includedServiceOffers.partnerPromoCodes,
                    'VISIBLE',
                  )
                "
              />
            </template>
          </Step>
        </div>
        <PromoCodeStep
          v-if="isWithStickyScroll"
          :category="product.tracking.categoryName"
          :model="product.model"
          :partner-promo-codes="product.includedServiceOffers.partnerPromoCodes"
          without-left-template
        />
        <Proposal
          v-if="isWithStickyScroll"
          ref="proposal"
          :is-focused="isProposalFocused"
          :mobile-plan="selectedMobilePlan"
          :price
          :price-without-subsidies
          :product
          :selected-color-code
          :selected-offer
          :selected-options
          :tracking="productTracking"
          without-left-template
          @focus="() => handleFocusedProposal()"
          @mouseenter="() => handleFocusedProposal()"
        >
          <template #details>
            <slot name="details" />
          </template>
        </Proposal>
      </div>
    </aside>

    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <aside
      v-for="(step, index) in funnelWithoutStickyScrollSteps"
      :key="step.id"
      ref="steps"
      class="py-32 md:py-36"
      @focus="
        () => handleFocusedStep(index + 1 + funnelWithStickyScrollSteps.length)
      "
      @mouseenter="
        () => handleFocusedStep(index + 1 + funnelWithStickyScrollSteps.length)
      "
      @mouseover="
        () => handleFocusedStep(index + 1 + funnelWithStickyScrollSteps.length)
      "
      @touchstart="
        () => handleFocusedStep(index + 1 + funnelWithStickyScrollSteps.length)
      "
    >
      <StepTradeIn
        v-if="step.id === 'trade_in'"
        :category="product.tracking.categoryName"
        :is-focused="
          isFocusedStep(index + 1 + funnelWithStickyScrollSteps.length)
        "
        :model="product.model"
        :price
      />

      <Step
        v-else
        :is-focused="
          isFocusedStep(index + 1 + funnelWithStickyScrollSteps.length)
        "
        :product
        :step
      >
        <template #description-mobile_plan>
          <StepDescriptionMobilePlan :offers />
        </template>

        <template #guidance-color>
          <Gallery
            :allow-media-viewer="false"
            :allow-partners-images="false"
            class="mb-16 w-full"
            :images
            :product
            :tracking-product-model="product.model"
            tracking-zone="pp_step_color_carousel"
          />
        </template>

        <template #step-end-mobile_plan>
          <StepEndMobilePlan :step :tracking-model="product.model" />
        </template>

        <template #step-end-dual_sim>
          <VisibleByVerizonSIMAdvertisement
            v-if="
              hasPartnerPromoCode(
                product.includedServiceOffers.partnerPromoCodes,
                'VISIBLE',
              )
            "
          />
        </template>
      </Step>
    </aside>

    <PromoCodeStep
      v-if="!isWithStickyScroll"
      :category="product.tracking.categoryName"
      :model="product.model"
      :partner-promo-codes="product.includedServiceOffers.partnerPromoCodes"
    />

    <Proposal
      v-if="(!isWithStickyScroll && funnel.length > 0) || funnel.length === 1"
      ref="proposal"
      :is-focused="isProposalFocused"
      :mobile-plan="selectedMobilePlan"
      :price
      :price-without-subsidies
      :product
      :selected-color-code
      :selected-offer
      :selected-options
      :tracking="productTracking"
      @focus="() => handleFocusedProposal()"
      @mouseenter="() => handleFocusedProposal()"
    >
      <template #details>
        <slot name="details" />
      </template>
    </Proposal>
    <AdditionalInfo
      v-if="
        funnel.length === 0 &&
        technicalSpecifications &&
        selectedOffer &&
        selectedOffer.offerLegacyId
      "
      class="ml-auto mr-0 md:w-2/3 lg:w-1/2"
      :listing-id="selectedOffer.offerLegacyId"
      :technical-specifications
    />
  </div>

  <ClientOnly>
    <NewBatteryModal :device-name="product.titles.raw" />
    <SimModalExperiment v-if="showSimModal && eSimExperimentEnabled" />
    <SimModal v-else-if="showSimModal" />
    <ProcessorModal v-if="showProcessorModal" />
    <BouyguesOffersDrawer v-if="showBouyguesModal" :offers />
    <ConditionDescriptionModal
      v-if="funnel[0]"
      :brand="product.brand"
      :category-id="product.tracking.categoryId"
      :options="funnel[0].options"
    />
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useRoute, useRouter, useState } from '#imports'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

import { type Price } from '@backmarket/http-api'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { type GetBreadcrumbResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/breadcrumb'
import {
  type GetPickersResponse,
  type GetServicesPickersResponse,
  hasItemExtraData,
} from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { type GetTechnicalSpecificationsResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/technical-specifications'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { tw } from '@backmarket/utils/string/tw'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { useIntersectionObserver } from '@vueuse/core'

import StepTradeIn from '~/scopes/buyback/swap/components/StepSwap/StepTradeIn.vue'
import { useESimExperiment } from '~/scopes/product/composables/useESimExperiment'

import { useNoGradeSteps } from '../../composables/useNoGradeSteps'
import { useProductTracking } from '../../composables/useProductTracking'
import { useUrlParams } from '../../composables/useUrlParams'
import { MODAL_NAMES } from '../../constants'
import { hasPartnerPromoCode } from '../../utils/hasPartnerPromoCode'
import AdditionalInfo from '../AdditionalInfo/AdditionalInfo.vue'
import Gallery from '../Gallery/Gallery.vue'
import {
  getIsStepBatteryAvailable,
  getIsStepBodyAvailable,
  getIsStepScreenAvailable,
  getStepScreenUpdate,
  stepStatusesToGradeOfferType,
} from '../NoGrade/NoGrade.utils'
import { getDescriptionStickyBar } from '../NoGrade/utils/getDescription'

import translations from './CustomizationFunnel.translations'
import BouyguesOffersDrawer from './components/BouyguesOffersDrawer/BouyguesOffersDrawer.vue'
import ConditionDescriptionModal from './components/ConditionDescriptionModal/ConditionDescriptionModal.vue'
import ConditionGuidance from './components/ConditionGuidance/ConditionGuidance.vue'
import NewBatteryModal from './components/NewBatteryModal/NewBatteryModal.vue'
import ProcessorModal from './components/ProcessorModal/ProcessorModal.vue'
import PromoCodeStep from './components/PromoCodeStep/PromoCodeStep.vue'
import Proposal from './components/Proposal/Proposal.vue'
import SimModal from './components/SimModal/SimModal.vue'
import SimModalExperiment from './components/SimModalExperiment/SimModalExperiment.vue'
import Step from './components/Step/Step.vue'
import StepDescriptionMobilePlan from './components/StepDescriptionMobilePlan/StepDescriptionMobilePlan.vue'
import StepEndMobilePlan from './components/StepEndMobilePlan/StepEndMobilePlan.vue'
import StepIncompatibleKeyboard from './components/StepIncompatibleKeyboard/StepIncompatibleKeyboard.vue'
import VisibleByVerizonSIMAdvertisement from './components/VisibleByVerizonSIMAdvertisement/VisibleByVerizonSIMAdvertisement.vue'
import { createCustomizationFunnelData } from './utils/createCustomizationFunnelData'

const props = withDefaults(
  defineProps<{
    breadcrumb?: GetBreadcrumbResponse | null | undefined
    selectedOffer: NonNullable<GetPickersResponse['selectedOffer']>
    pickers: GetPickersResponse
    pickersServices: GetServicesPickersResponse['pickerGroups']
    price: Price
    priceWithoutSubsidies?: Price | null
    priceWithoutTradeIn?: Price | null
    product: GetProductResponse
    productTracking: ReturnType<typeof useProductTracking>
    selectedMobilePlan?: MobilePlanOffer
    technicalSpecifications?: GetTechnicalSpecificationsResponse | null
  }>(),
  {
    breadcrumb: undefined,
    selectedMobilePlan: undefined,
    priceWithoutSubsidies: undefined,
    priceWithoutTradeIn: null,
    technicalSpecifications: undefined,
  },
)

const route = useRoute()
const router = useRouter()
const i18n = useI18n()
const experiments = useExperiments()
const { isEnabled: eSimExperimentEnabled } = useESimExperiment()
const { getDiscountedPrice } = useBuybackOffer()
const proposal = ref<HTMLDivElement | null>(null)
const proposalObserver = ref<ReturnType<typeof useIntersectionObserver>>()
const lastYPosition = ref(0)
const scrollDirection = ref<'up' | 'down'>()
const currentStep = useState('current-step', () => 0)
const steps = ref<HTMLDivElement[] | null>([])
const firstStep = ref<HTMLDivElement | null>()
const stepsObserver = ref<ReturnType<typeof useIntersectionObserver>>()
const stepsIntersections = ref<Record<string, number>>({})
const { grade, offerType, withNoGrade, mobilePlan } = useUrlParams()
const { stepStatuses, activeStep } = useNoGradeSteps(props.product.model)

const stepsConcat = computed(() => {
  if (firstStep.value && steps.value) {
    return [firstStep.value, ...steps.value]
  }

  return []
})

const funnel = computed(() => {
  return createCustomizationFunnelData(
    i18n,
    props.pickers.pickerGroups,
    props.pickersServices,
    props.product,
    props.priceWithoutSubsidies,
    getDiscountedPrice(props.priceWithoutTradeIn),
    withNoGrade,
    experiments['experiment.ppNoGrade'],
  )
})

/**
 * Positioning sticky in the center of the "container"
 *
 * I'm adding a comment to this class because it's a bit tricky to understand and to
 * make it easier to change in the future:
 *
 * - `supports-[not(container-type:size)]:top-[calc(15svh+4rem)]` means that the `top-...` part is
 *   applied only when the browser does not support the `container-type: size` CSS property (using `@supports`).
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/container-type
 *
 * - `dvh` is the "dynamic viewport's height". It is different from `vh` because it takes into account the
 *   browser's UI elements (like the address bar in mobile browsers) and adapts to their presence.
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/length#dynamic
 *
 * - `clamp(8rem, calc(50svh - 50cqw), 100vh)` is used to ensure the sticky bar is positioned
 *   responsively within a range. `clamp()` takes three arguments: a minimum value, a preferred value,
 *   and a maximum value. Here, it ensures the top position is at least 8rem, ideally at the center
 *   of the container (50svh - 50cqw), but no more than 100vh. The most important part is the `50cqw`. It
 *   is a container query length unit that represents the width of the container.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/length#container_query_length_units
 */
const stickyInTheCenter = tw`supports-[not(container-type:size)]:top-[calc(15dvh+4rem)] top-[clamp(8rem,calc(50svh-50cqw),100vh)]`

const isWithoutStickyScroll = computed(() => {
  return ['noVariation', 'withoutStickyScroll'].includes(
    experiments['experiment.ppStickyScrolling'],
  )
})

const isHybridStickyScroll = computed(() => {
  return experiments['experiment.ppStickyScrolling'] === 'hybridStickyScroll'
})

const isWithStickyScroll = computed(() => {
  return experiments['experiment.ppStickyScrolling'] === 'withStickyScroll'
})

const funnelWithStickyScrollSteps = computed(() => {
  return funnel.value.filter((step) => {
    if (step.id === 'grades') {
      return false
    }

    if (isWithoutStickyScroll.value) {
      return false
    }

    if (
      isHybridStickyScroll.value &&
      ['mobile_plan', 'trade_in'].includes(step.id)
    ) {
      return false
    }

    return true
  })
})

const funnelWithoutStickyScrollSteps = computed(() => {
  return funnel.value.filter((step) => {
    if (step.id === 'grades') {
      return false
    }

    if (isWithoutStickyScroll.value) {
      return true
    }

    if (
      isHybridStickyScroll.value &&
      ['mobile_plan', 'trade_in'].includes(step.id)
    ) {
      return true
    }

    return false
  }, [])
})

const selectedOptions = computed(() => {
  const hasUnavailableScreen = !getIsStepScreenAvailable(props.pickers)
  const hasUnavailableBody = !getIsStepBodyAvailable(props.pickers)
  const stepsScreenUpdate = getStepScreenUpdate(props.pickers)
  const customSelection = stepStatusesToGradeOfferType(
    stepStatuses.value,
    activeStep.value,
    stepsScreenUpdate,
    props.pickers,
  )
  const hasUnavailableBattery = !getIsStepBatteryAvailable(
    props.pickers,
    customSelection,
  )

  const variants = funnel.value
    ?.reduce<Array<string>>((acc, { options, type }) => {
      if (type !== 'service') {
        const label =
          options?.find(({ selected }) => !!selected)?.label ?? undefined
        if (label) {
          acc.push(typeof label === 'object' ? i18n(label) : label)
        }
      }

      return acc
    }, [])
    .filter(Boolean)

  if (experiments['experiment.ppNoGrade'] === 'withNoGrade' || withNoGrade) {
    return [
      ...getDescriptionStickyBar(
        i18n,
        activeStep.value,
        stepStatuses.value,
        hasUnavailableScreen,
        hasUnavailableBody,
        hasUnavailableBattery,
        grade.value as 9 | 10 | 11 | 12 | null,
        (offerType.value as 0 | 7 | null) ?? 0,
      ),
      ...variants,
    ]
  }

  return variants
})

const selectedTitle = computed(() => {
  return selectedOptions.value.join(' - ')
})

const selectedColorCode = computed(() => {
  return (
    funnel.value
      ?.find(({ id }) => id === 'color')
      ?.options?.find(({ selected }) => !!selected)?.color ?? undefined
  )
})

const offers = computed(() => {
  const mobilePlanItems = props.pickersServices?.find(
    ({ id }) => id === 'mobile_plan',
  )?.items

  if (!mobilePlanItems) return []

  return mobilePlanItems
    .filter(hasItemExtraData)
    .map(({ mobilePlanOffer }) => mobilePlanOffer)
})

const showSimModal = computed(() => {
  return props.pickers.pickerGroups?.some(({ id }) => id === 'dual_sim')
})
const showBouyguesModal = computed(() => {
  return props.pickers.pickerGroups?.some(({ id }) => id === 'mobile_plan')
})
const showProcessorModal = computed(() => {
  return props.pickers.pickerGroups?.some(
    ({ id }) =>
      id === 'processor_type_and_speed' ||
      id === 'processor_type_and_graphic_card',
  )
})

const images = computed(() => {
  return props.product.images.slice(0, 3)
})

function isStepObserverInitialized() {
  return (
    stepsConcat.value &&
    stepsConcat.value.length > 0 &&
    stepsObserver.value?.isActive
  )
}

function handleFocusedStep(index: number) {
  if (isStepObserverInitialized()) {
    currentStep.value = index
  }
}

const isProposalObserverInitialized = computed(() => {
  return proposal.value && proposalObserver.value?.isActive
})

function handleFocusedProposal() {
  if (isProposalObserverInitialized.value && funnel.value) {
    currentStep.value = funnel.value.length
  }
}

const isProposalFocused = computed(() => {
  return isProposalObserverInitialized.value && funnel.value
    ? currentStep.value === funnel.value.length
    : true
})

function isFocusedStep(index: number) {
  return isStepObserverInitialized() ? currentStep.value === index : true
}

function addIntersectionObservers() {
  stepsObserver.value = useIntersectionObserver(
    stepsConcat,
    (entries) => {
      entries.forEach(({ target, intersectionRatio }) => {
        const index = stepsConcat.value.findIndex(
          (element) => element === target,
        )

        if (index == null) {
          return
        }

        stepsIntersections.value = {
          ...stepsIntersections.value,
          [index]: intersectionRatio,
        }
      })

      scrollDirection.value =
        lastYPosition.value > window.scrollY ? 'up' : 'down'
      lastYPosition.value = window.scrollY
    },
    {
      threshold: [0, 0.25, 0.5, 0.75, 1],
    },
  )

  proposalObserver.value = useIntersectionObserver(
    proposal.value,
    ([{ boundingClientRect, isIntersecting, intersectionRatio }]) => {
      // When more than 3/4 of a step is displaying, it became the focus state.
      // We remove the focus the previous step when it starts to disappear.
      if (
        isIntersecting &&
        parseFloat((Math.round(intersectionRatio * 4) / 4).toFixed(2)) >= 0.25
      ) {
        if (
          (lastYPosition.value > window.scrollY &&
            boundingClientRect.top < 0) ||
          (lastYPosition.value <= window.scrollY && boundingClientRect.top > 0)
        ) {
          handleFocusedProposal()
        }
      }

      scrollDirection.value =
        lastYPosition.value > window.scrollY ? 'up' : 'down'
      lastYPosition.value = window.scrollY
    },
    {
      threshold: [0, 0.25, 0.5, 0.75, 1],
    },
  )
}

function clearIntersectionObservers() {
  stepsIntersections.value = {}

  stepsObserver.value?.stop()
  stepsObserver.value = undefined

  proposalObserver.value?.stop()
  proposalObserver.value = undefined
}

onMounted(() => {
  addIntersectionObservers()
})

onUnmounted(() => {
  clearIntersectionObservers()
})

watch([funnel], () => {
  clearIntersectionObservers()
  addIntersectionObservers()
})

watch(stepsIntersections, () => {
  let intersections = Object.entries(stepsIntersections.value)

  if (!intersections.length) {
    return
  }

  if (scrollDirection.value === 'down') {
    intersections = intersections.reverse()
  }

  const step = intersections.reduce((acc, [index, intersection]) => {
    if (!acc) return [index, intersection]
    if (acc[1] < intersection) return [index, intersection]

    return acc
  })

  if (step) handleFocusedStep(parseInt(step[0], 10))
})

watch(
  [() => props.pickersServices],
  () => {
    const areMobilePlanAvailable = funnel.value
      .find(({ id }) => id === 'mobile_plan')
      ?.options.slice(1)
      .every((o) => !o.available)

    if (areMobilePlanAvailable && mobilePlan.value) {
      router.replace({
        path: route.path,
        query: {
          ...route.query,
          mobilePlanOfferId: undefined,
        },
        hash: route.hash,
      })
    }
  },
  { immediate: true },
)
</script>
